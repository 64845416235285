<template>
    <div>
        <el-card class="box-card">
            <h2>注册</h2>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-position="left"
                label-width="80px" class="demo-ruleForm">
                <el-form-item label="用户名" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>

                <el-form-item label="密码" prop="pass">
                    <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="pwd">
                    <el-input type="password" v-model="ruleForm.pwd" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div class="btnGroup">
                <el-button type="primary" @click="submitForm('ruleForm')" v-loading="loading">提交</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
                <el-button @click="goBack">返回</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.ruleForm.pwd !== "") {
                    this.$refs.ruleForm.validateField("pwd");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                name: "",
                pass: "",
                pwd: "",
            },
            rules: {
                name: [
                    { required: true, message: "用户名不能为空！", trigger: "blur" },
                ],
                pass: [{ required: true, validator: validatePass, trigger: "blur" }],
                pwd: [
                    { required: true, validator: validatePass2, trigger: "blur" },
                ],
            },
            loading: false
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                this.loading = true;
                if (valid) {
                    let _this = this;
                    this.axios({     // axios 向后端发起请求
                        url: "/api/user/register",  // 请求地址
                        method: "post",             // 请求方法
                        headers: {                  // 请求头
                            "Content-Type": "multipart/form-data",
                        },
                        data: { 
                            acc: _this.ruleForm.name,
                            pwd: _this.ruleForm.pwd,
                            name: _this.ruleForm.name,
                        },
                    }).then((res) => { // 当收到后端的响应时执行该括号内的代码
                        if (res.data.success && res.data.code === 200) {  // 当响应的成功标志为 true 且编码为 200 时，说明注册成功
                            // 显示后端响应的成功信息
                            this.$message({
                                message: res.data.message,
                                type: "success",
                            });
                            _this.loading = false;
                            _this.resetForm(formName); // 重置表单
                        } else {  // 其他情况均为注册失败
                            // 显示注册失败信息
                            this.$message({
                                message: "注册失败，请重试。",
                                type: "error",
                            });
                            _this.loading = false;
                        }
                    }).catch(() => {
                        _this.loading = false;
                        this.$message({
                            message: "注册请求失败，请检查网络。",
                            type: "error",
                        });
                    });
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
/* 设置注册面板居中，宽度为400px */
.box-card {
    margin: auto auto;
    width: 400px;
}

/* 设置注册面板中的表单居中 */
.demo-ruleForm {
    margin: auto auto;
}
</style>