<template>
    <div>
        <h2>欢迎！</h2>
        <router-link to="/login">
            <el-button> 登出 </el-button>
        </router-link>
        <br />
        <router-link to="/renewpwd">
            <el-button> 更新密码 </el-button>
        </router-link>
        <br />
        <router-link to="/table">
            <el-button> 查看表格 </el-button>
        </router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {
                name: "",
                id: null,
            },
        };
    },
};
</script>

<style scoped>
</style>