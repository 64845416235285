<template>
  <div>
    <el-card class="box-card">
      <h2>登录</h2>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-position="left"
        label-width="70px"
        class="login-from"
      >
        <el-form-item label="用户名" prop="acc">
          <el-input v-model="ruleForm.acc"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwd">
          <el-input
            type="pwd"
            v-model="ruleForm.pwd"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btnGroup">
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          v-loading="loading"
          >登录</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <router-link to="/register">
          <el-button style="margin-left: 10px">注册</el-button>
        </router-link>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        acc: "",
        pwd: "",
      },
      rules: {
        acc: [
          { required: true, message: "用户名不能为空！", trigger: "blur" },
        ],
        pwd: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
        ],
      },
      loading: false, // 是否显示加载动画
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let _this = this;
          this.axios({
            url: "/api/user/login",
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              acc: _this.ruleForm.acc,
              pwd: _this.ruleForm.pwd,
            },
          }).then((res) => {
            if (res.data.success && res.data.code === 200) {
              sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
              this.$router.push('/home');
              this.$message({
                message: res.data.message,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.message || "登录失败，请重试。",
                type: "warning",
              });
            }
            _this.loading = false;
          }).catch(() => {
            _this.loading = false;
            this.$message({
              message: "登录请求失败，请检查网络。",
              type: "error",
            });
          });
        } else {
          console.log("error submit!!");
          this.loading = false;
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
/* 设置登录面板居中，宽度为400px */
.box-card {
  margin: auto auto;
  width: 400px;
}
/* 设置登录面板中的表单居中 */
.login-from {
  margin: auto auto;
}
</style>