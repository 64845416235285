<template>
    <div>
        <el-card class="box-card">
            <h2>修改密码</h2>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-position="left"
                label-width="80px" class="demo-ruleForm">
                <el-form-item label="账号" prop="acc">
                    <el-input v-model="ruleForm.acc" ></el-input>
                </el-form-item>
                <el-form-item label="原密码" prop="pwd">
                    <el-input type="password" v-model="ruleForm.pwd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPwd">
                    <el-input type="password" v-model="ruleForm.newPwd" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div class="btnGroup">
                <el-button type="primary" @click="submitForm('ruleForm')" v-loading="loading">提交</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        var validateNewPwd = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入新密码"));
            } else if (value === this.ruleForm.pwd) {
                callback(new Error("新密码不能与原密码相同"));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                acc: "", // 账号信息通常由后端提供，此处假设前端已知
                pwd: "",
                newPwd: "",
            },
            rules: {
                acc: [
                    { required: true, message: "账号不能为空！", trigger: "blur" },
                ],
                pwd: [
                    { required: true, message: "原密码不能为空！", trigger: "blur" },
                ],
                newPwd: [
                    { required: true, validator: validateNewPwd, trigger: "blur" },
                ],
            },
            loading: false,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                this.loading = true;
                if (valid) {
                    let _this = this;
                    this.axios({
                        url: "/api/user/updatePwd", // 假设这是后端提供的修改密码接口
                        method: "post",
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        data: {
                            acc: _this.ruleForm.acc,
                            pwd: _this.ruleForm.pwd,
                            newPwd: _this.ruleForm.newPwd,
                        },
                    }).then((res) => {
                        if (res.data.success && res.data.code === 200) {
                            this.$message({
                                message: res.data.message,
                                type: "success",
                            });
                            _this.loading = false;
                            _this.resetForm(formName); // 重置表单
                        } else {
                            this.$message({
                                message: res.data.message,
                                type: "error",
                            });
                            _this.loading = false;
                        }
                    }).catch(() => {
                        _this.loading = false;
                        this.$message({
                            message: "请求失败，请检查网络。",
                            type: "error",
                        });
                    });
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>

<style scoped>
.box-card {
    margin: auto auto;
    width: 400px;
}

.demo-ruleForm {
    margin: auto auto;
}
</style>