<template>
    <div class="table">
        <!-- 表格 -->
        <el-table :data="filteredUserList" style="width: 100%">
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="acc" label="账号"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="score" label="分数"></el-table-column>
            <!-- 搜索框 -->
            <el-table-column label="操作" align="right">
                <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" placeholder="输入关键字搜索" @input="handleSearch" />
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                :page-sizes="[1,2,3]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="count">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageNum: 1, // 当前页
            pageSize: 2, // 每页显示条数
            userList: [], // 用户列表
            count: 0, // 数据总行数
            search: '', // 搜索关键字
        };
    },
    computed: {
        filteredUserList() {
            if (this.search) {
                return this.userList.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
            }
            return this.userList;
        }
    },
    methods: {
        // 获取用户列表数据
        fetchData() {
            this.axios({
                url: "/api/user/getUserListByPage",
                method: "post",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                },
            }).then((res) => {
                if (res.data.success && res.data.code === 200) {
                    this.userList = res.data.data.userList;
                    this.count = res.data.data.count;
                } else {
                    this.$message.error(res.data.message);
                }
            }).catch((error) => {
                this.$message.error("请求失败：" + error.message);
            });
        },
        // 处理搜索
        handleSearch() {
            this.pageNum = 1; // 重置为第一页
            this.fetchData(); // 重新获取数据
        },
        // 处理每页条数改变事件
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1; // 重置为第一页
            this.fetchData(); // 重新获取数据
        },
        // 处理当前页改变事件
        handleCurrentChange(val) {
            this.pageNum = val;
            this.fetchData(); // 重新获取数据
        }
    },
    mounted() {
        this.fetchData(); // 组件挂载时获取数据
    },
};
</script>

<style scoped>
.table {
    margin: 20px;
}

.block {
    margin-top: 20px;
}
</style>